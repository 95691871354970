<template>
<div>
  <div class="container mx-auto flex justify-center pb-10">
    <div class="h-full grid gap-4 pt-10 grid-cols-2 md:grid-cols-4 md:gap-36">
      <div class="flex flex-col text-white justify-start">
        <img class="md:w-48" src="@/assets/rslogo.png" />
        <p>South Africa's #1 Platform <br />for profitable property deals</p>
      </div>
      <div class="flex flex-col text-white justify-start">
        <p class="font-bold text-xl pb-5">Locations</p>
        <a class="pb-5 text-xl font-light" href=""> Gauteng</a>
        <a class="pb-5 text-xl font-light" href=""> Western Cape</a>
        <a class="pb-5 text-xl font-light" href=""> KwaZulu Natal</a>
        <a class="pb-5 text-xl font-light" href=""> Eastern Cape</a>
        <a class="pb-5 text-xl font-light" href=""> Limpopo</a>
      </div>
      <div class="flex flex-col text-white justify-start">
        <p class="font-bold text-xl pb-5">Brokers</p>
        <a class="pb-5 text-xl font-light" href=""> Join to List Property</a>
        <a class="pb-5 text-xl font-light" href=""> Broker Pricing Plans</a>
        <p class="font-bold text-xl pb-5">Service Providers</p>
        <a class="pb-5 text-xl font-light" href=""> Join to List Services</a>
        <a class="pb-5 text-xl font-light" href=""> Service Providers Pricing Plans</a>
      </div>
      <div class="flex flex-col text-white justify-start">
        <p class="font-bold text-xl pb-5">Contact Us</p>
        <a class="pb-5 text-xl font-light" href="tel:0745775857">Phone: 0745775857</a>
        <a class="pb-5 text-xl font-light" href="mailto:info@fliprop.com">info@fliprop.com</a>
        <a class="pb-5 text-xl font-light" href=""> Help</a>
      </div>
    </div>
  </div>
    <div class="container mx-auto  divide-white pb-20">
      <hr class="pb-10">
    <div class="text-white flex flex-wrap flex-row gap-20 justify-center">
      <p class="pb-5 text-xl font-light">Copyright 2021</p>
      <a class="pb-5 text-xl font-light">Privacy Policy</a>
      <a class="pb-5 text-xl font-light">Terms & Conditions</a>
      <a class="pb-5 text-xl font-light">Our Partners</a>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainFooterComponent',
  props: [
    'classes'
  ]
}
</script>
<style scoped lang="scss">
</style>
