<template>
  <div>
    <div class="container mx-auto">
      <p class="text-xl text-black text-4xl font-black text-center pt-10">
        How Fliprop works
      </p>
      <p class="text-xl text-center py-5 pb-10">
        Here's how we make buying, fixing and flipping a property <br />
        easy, fast and stress-free.
      </p>
    </div>
    <div class="container mx-auto">
      <div class="grid grid-cols-2 gap-x-10 gap-y-5 px-10 md:grid-cols-4 ">
        <div class="flex-1 space-x-0" v-for="item in items" :key="item.title">
          <img
            class="rounded object-cover w-80 h-80"
            :src="item.image"
            :alt="'test'"
          />
          <div class="mt-2">
            <div>
              <div class="text-xl text-flipblue uppercase font-bold pb-2">
                {{ item.title }}
              </div>
              <div class="font-bold text-flipdarkblue pb-2">
                {{ item.sub }}
              </div>
              <div class="mt-2 text-md md:w-80 text-black-600">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowComponent',
  data: () => ({
    items: [
      {
        image: require('@/assets/images/how_it_works_1.jpg'),
        title: 'STEP 1',
        sub: 'Property Research',
        content: 'Find properties under market value, in your preferred area that match your budget'
      },
      {
        image: require('@/assets/images/how_it_works_2.jpg'),
        title: 'STEP 2',
        sub: 'Profit Estimates',
        content: 'Get estimated profit margins and inspection reports for each property'
      },
      {
        image: require('@/assets/images/how_it_works_3.jpg'),
        title: 'STEP 3',
        sub: 'Renovation Quotes',
        content: 'Find properties under market value, in your preferred area that match your budget'
      },
      {
        image: require('@/assets/images/how_it_works_4.jpg'),
        title: 'STEP 4',
        sub: 'Get in touch',
        content: 'Find properties under market value, in your preferred area that match your budget'
      }
    ]
  })
}
</script>
<style scoped lang="scss">
</style>
