<template>
<div>
  <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap mt-10 p-8 bg_lightblue cornered">
    <div class="col-span-12">
      <div>
        <h3 class="text-2xl text-bold mb-3">Show Days</h3>
    </div>
    <table class="table-fixed w-100 text-left show_detail_list text-base">
      <thead text-base>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Details/Comments</th>
        </tr>
      </thead>
  <tbody>
    <tr>
      <td>23 June 2021</td>
      <td>14:00 - 16:00</td>
      <td>Please ring 41 on the intercom</td>
    </tr>
    <tr>
      <td>23 June 2021</td>
      <td>14:00 - 16:00</td>
      <td>Please ring 41 on the intercom</td>
    </tr>
    <tr>
      <td>23 June 2021</td>
      <td>14:00 - 16:00</td>
      <td>Please ring 41 on the intercom</td>
    </tr>
  </tbody>
</table>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'ShowDetails'
}
</script>
