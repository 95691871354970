import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './index.css'
import './assets/styles.scss'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import { SearchIcon, HeartIcon, ViewGridIcon, MapIcon, FilterIcon, ShareIcon, SaveIcon, CashIcon } from '@vue-hero-icons/outline'

Vue.component('SearchIcon', SearchIcon)
Vue.component('HeartIcon', HeartIcon)
Vue.component('ViewGridIcon', ViewGridIcon)
Vue.component('MapIcon', MapIcon)
Vue.component('FilterIcon', FilterIcon)
Vue.component('ShareIcon', ShareIcon)
Vue.component('SaveIcon', SaveIcon)
Vue.component('CashIcon', CashIcon)

Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
