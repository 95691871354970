<template>
 <div>
   <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap mt-10 image_gallery">
      <div class="col-span-8">
        <img src="@/assets/content/place_large.png" alt="" class="w-full"/>
      </div>

      <div class="col-span-4">
        <img src="@/assets/content/place_large.png" alt="" class="w-full mb-4"/>
        <img src="@/assets/content/place_large.png" alt="" class="w-full"/>
      </div>
    </div>
 </div>
</template>

<script>
export default {
  name: 'PropertyImages'
}
</script>
