<template>
<div>
  <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap mt-10">
    <div class="col-span-12">
      <div class="border_std p-8">
        <h3 class="text-2xl text-bold mb-3">Overview</h3>
      <p class="text-base">
        Perfect for families, first time buyers and investors alike, this three bedroomed apartment is not one to be missed. Sunny and spacious, this ground floor apartment comprises polished parquet flooring throughout. The warm living room is generously sized and leads out to an exclusive use patio that overlooks the leafy communal garden. This charming kitchen offers ample cupboard and counter space. All three spacious bedrooms have built-in cupboards and have quick access to the family bathroom and separate loo. This unit is complete with a secure parking. Security features include; CCTV cameras throughout the block and remote access to the pedestrian gate and electric gate. Centrally located within walking distance to Riverside Mall, Rondebosch Park and Leading schools.
      </p>
    </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'Overview'
}
</script>
