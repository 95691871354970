
<template>
  <div class="">
    <div class="relative">
      <p class="absolute right-10 top-10" @click="$router.go(-1)">CLOSE</p>
    </div>
    <div class="flex items-center justify-center h-screen">
      <div class="flex flex-col">
        <p
          class="
            text-black text-center text-4xl
            md:text-5xl
            font-black
            py-10
            md:py-20
            leading-normal
          "
        >
          Search for properties
        </p>
        <div class="flex flex-col md:flex-row justify-center">
          <div class="flex flex-col px-10 md:pl-0">
            <label for="" class="text-white font-thin">Location</label>
            <input
              type="text"
              class="
                border-2 border-gray-400
                form-input
                px-4
                py-3
                h-20
                text-black
                rounded-2xl
                w-30
                md:w-80
              "
              placeholder="Enter your location"
            />
          </div>
          <div class="flex flex-col px-10 md:pl-0 pt-10 md:pt-0">
            <label for="" class="text-white font-thin">Price range</label>
            <select
              class="
                border-2 border-gray-400
                form-select
                px-4
                py-3
                rounded-2xl
                h-20
                text-black
                w-50
                md:w-80
              "
            >
              <option value="test" class="text-gray">Select a range</option>
              <option value="test" class="text-gray">100k - 200k</option>
            </select>
          </div>
          <div class="flex px-5 w-100 md:w-100">
            <button
              class="
                transition
                ease-in-out
                duration-500
                relative
                top-5
                bg-blue-500
                hover:bg-blue-700
                text-white
                font-bold
                py-2
                px-4
                h-20
                rounded-full
                w-100
                md:w-40
                flex
                items-center
                justify-center
              "
            >
              <SearchIcon class="h-8 w-8" />
            </button>
          </div>
        </div>
        <a
          href=""
          class="text-center text-white pb-20 pt-10 font-thin underline text-xl"
          >How we estimate your profit potential</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  }
}
</script>
