<template>
  <div class="quote_block">
    <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap items-center">
      <div class="col-span-5 text-center ">
        <img src="@/assets/content/reno.png" alt="" class="reno mx-auto"/>
        <p class="text-base">Nrenovation Building Contractors</p>
              <div class="star_wrapper">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star neg"></i>
              </div>
              <p class="text_link text-xs">Number of jobs: 41</p><br>
      </div>
      <div class="col-span-7 bg_primary p-6">
        <p class="text-white text-xs">Estimated Total Renovation Cost:</p>
        <p class="text-white text-2xl mb-5">R50 000</p>

        <p class="text-white text-xs">Total Renovation Time Estimate:</p>
        <p class="text-white text-2xl">3 to 5 months</p>
        <p><span class="yellow_info_box_sm"><p class="text-xxs"><CashIcon class="h-6 w-6 icon"/> Profit Estimate: R 120K (20% return)</p></span> <a href="#" class="link_icon"><i class="fas fa-info-circle text-white ml-2"></i></a></p>
        <a href="#" class="btn_stroke_white detail_button text-xs">View Details</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SingleQuote'
}
</script>
