<template>
  <div class="">
    <div class="">
      <div
        class="bg-no-repeat bg-cover bg-top"
        v-bind:style="{
          backgroundImage:
            'linear-gradient(rgba(00, 00, 00, 0.1), rgba(00, 00,00, 0.5)), url(' +
            require('@/assets/content/home_loan_deposit.jpeg') +
            ')',
        }"
      >
        <p
          class="pl-48
            text-white text-left text-3xl

            text-xl
            md:text-5xl
            font-black
            pt-20
            pb-10
            max-w-[900px]
            text-left
          "
        >
          Get access to property inspection reports and get notified of new
          listings
        </p>
        <button
          class="
            mb-20
            ml-48
            px-10
            transition
            ease-in-out
            duration-500
            bg-flipblue
            hover:bg-flipyellow
            text-white
            font-thin
            h-20
            rounded-full
            w-100
            md:w-60
          "
        >
          Sign Up Today
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageCallToAction'
}
</script>
<style scoped lang="scss">
</style>
