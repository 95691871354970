<template>
  <div>
https://www.sketch.com/s/c4bc07c0-9c58-4840-95a5-6aab62307606/a/v81JVWe
page 13

similar to http://localhost:8080/search page you can copy and edit the form
  </div>
</template>
<script>
export default {
}
</script>
