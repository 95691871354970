<template>
  <div class="container mx-auto">
    <div
    >
      <div class="flex flex-col md:flex-row py-5">
        <img class="md:w-24" src="@/assets/rslogo.png" />
        <div
          class="
            flex-1
            text-black text-lg
            flex flex-row
            justify-around
            md:justify-end
          "
        >
          <p class="pl-10">Sign in</p>
          <p class="pl-10">Sign Up</p>
          <p class="pl-10">Add a property</p>
          <span class="pl-10" @click="$router.push('search')"
            ><SearchIcon class="h-6 w-6"
          /></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StripHeaderComponent'
}
</script>
<style scoped lang="scss">
</style>
