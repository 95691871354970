<template>
  <div>
    <home-header />
    <div class="container_custom mx-auto pt-4">
      <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap mt-10">
      <div class="col-span-12">
          <h3 class="text-4xl text-bold mb-3">Sign Up</h3>
      </div>
    </div>
    <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap mt-10">
      <div class="col-span-5">
          <div class="form_item">
            <label for="name">Enter your name</label>
            <input type="text" class="text_field" name="name">
          </div>
          <div class="form_item">
            <label for="name">Enter your name</label>
            <input type="text" class="text_field" name="name">
          </div>
          <div class="form_item">
            <label for="name">Enter your name</label>
            <input type="text" class="text_field" name="name">
          </div>
          <div class="grid grid-cols-12 grid-flow-row gap-4 flex flex-wrap">
            <div class="col-span-6">
              <p>Are you preapproved for a bond? </p>
            </div>
            <div class="col-span-3">
            <input class="mr-3" type="checkbox" id="Yes" name="Yes" value="Yes">
<label for="Yes">Yes</label>
          </div>
          <div class="col-span-3">
            <input class="mr-3" type="checkbox" id="No" name="No" value="No">
<label for="No">No</label>
          </div>
          </div>
          <a href="#" class="button_blue"> SIGN UP</a>
          <p class="mt-5 text-center">Already have an account? <a href="#" class="mt-10 text-link text-underline">Sign in</a></p>

          <div class="blue_wrapper mt-5">
            <p class="text-center">Already have an account? <a href="#" class="mt-10 text-link text-underline">Sign in</a></p>
            <p class="mt-3 text-center">Already have an account? <a href="#" class="mt-10 text-link text-underline">Sign in</a></p>
          </div>
      </div>
      <div class="col-span-2 text-center">
          <h3 class="text-4xl text-bold mb-3">or</h3>
      </div>
      <div class="col-span-5">
          <a href="#" class="btn_stroke_square mb-5"> Sign Up with Facebook</a>
          <a href="#" class="btn_stroke_square mb-5"> Sign Up with Facebook</a>
          <a href="#" class="btn_stroke_square mb-5"> Sign Up with Facebook</a>
      </div>
    </div>
    <div class="bg-white-100 pt-20">
      <image-call-to-action class="pb-20" />
      <banner-advert :classes="'h-20 md:h-48'" class="pb-20" />
    </div>
    </div>
    <div class="bg-black">
      <main-footer />
    </div>
  </div>
</template>

<script>
import HomeHeader from '../../components/StripHeaderComponent.vue'
import BannerAdvert from '../../components/BannerAdvertComponent.vue'
import MainFooter from '../../components/MainFooterComponent.vue'
export default {
  components: {
    HomeHeader,
    BannerAdvert,
    MainFooter
  }
}
</script>
