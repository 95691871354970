<template>
  <div>
    1. add a my broker account menu component here
    2.add stats bar components here
    3. add broker account details component here - in it will be a menu item component as well for other parts

    https://www.sketch.com/s/c4bc07c0-9c58-4840-95a5-6aab62307606/a/AxV0Rvy
    page 23
  </div>
</template>
<script>
export default {
}
</script>
