<template>
  <div class="mt-10">
    <h3 class="text-2xl text-bold">Renovation Quotes </h3>
    <swiper ref="PropSwiper" :options="swiperOptions">
    <swiper-slide class="slide_area">
      <single-property />
    </swiper-slide>

    <swiper-slide class="slide_area">
      <single-property />
    </swiper-slide>

    <swiper-slide class="slide_area">
      <single-property />
    </swiper-slide>

    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
  </div>
</template>

<script>
import SingleProperty from '../property-details/SingleProperty.vue'
export default {
  name: 'carrousel',
  components: {
    SingleProperty
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 40,
        pagination: {
          el: '.swiper-pagination'
        }
        // Some Swiper option/callback...
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.PropSwiper.$swiper
    }
  },
  mounted () {
    console.log('Current Swiper instance object', this.swiper)
  }
}
</script>
