
<template>
  <div class="">
    <div class="relative">
      <p class="absolute right-10 top-10" @click="$router.push('/')">CLOSE</p>
    </div>
    <div class="flex items-center justify-center h-screen">
      <div class="flex flex-col">
        <p
          class="
            text-black text-center text-4xl
            md:text-5xl
            font-black
            py-10
            md:py-20
            leading-normal
          "
        >
          404. <br/>
          Page Not Found
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  }
}
</script>
