<template>
<div>
  <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap mt-10">
    <div class="col-span-12">
      <div class="img_wrapper relative">
        <img src="@/assets/content/place_large.png" alt="" class="w-full"/>
        <a class="like_action" href="#"><i class="far fa-heart"></i></a>
        <p><span class="yellow_info_box_area"><p class="text-xs"><CashIcon class="h-6 w-6 icon"/> Profit Estimate: R 120K (20% return)</p></span></p>
      </div>
      <div class="px-8 pt-5">
        <p class="text-xl">R1 200 00</p>
        <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap mt-3">
          <div class="col-span-8">
            <p class="text-base">3 Bedroom Apartment, Sandton</p>
            <p class="text-sm">8 Quotes from Renovators</p>
          </div>
          <div class="col-span-4">
            <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap">
                <div class="col-span-6 text-base">
                  <i class="fas fa-bed mr-2"></i> 3
                </div>

                <div class="col-span-6 text-base">
                  <i class="fas fa-bath mr-2"></i> 3
                </div>

                <div class="col-span-6 text-base">
                  <i class="fas fa-car mr-2"></i> 3
                </div>

                <div class="col-span-6 text-base">
                  <i class="fas fa-border-all mr-2"></i>116m<sup>2</sup>
                </div>

              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'SinlgeProperty'
}
</script>
