
<template>
  <div>
    <strip-header />

    <div class="bg-black text-white">
      <div class="container mx-auto py-5">
        <div class="flex flex-row justify-between">
          <div class="flex flex-row">
            <p class="font-medium">Refine your results</p>
          </div>
          <div class="flex flex-row gap-10 ">
            <div class="flex flex-row gap-2">
              <p class="font-medium">Sort by</p>
              <select name="" id="" class="rounded bg-black">
                <option value="">Price high to low</option>
                <option value="">Price low to high</option>
              </select>
            </div>
            <div class="flex gap-2">
              <MapIcon class="" /> Sort by
            </div>
            <div class="flex gap-2">
              <FilterIcon class="" /> Filters
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white-100">
      <div class="container mx-auto">
        <p class="font-black text-4xl pt-10">Property Deals in "cape town"</p>
      </div>
      <search-card-list class="pb-20" />
      <banner-advert :classes="'h-full'" class="pb-20" />
      <popular-suburbs class="pb-20" />
    </div>
    <div class="bg-white-100 pt-20">
      <banner-advert :classes="'h-20 md:h-48'" class="pb-20" />
    </div>
    <div class="bg-black">
      <main-footer />
    </div>
  </div>
</template>

<script>
import StripHeader from '../components/StripHeaderComponent.vue'
import SearchCardList from '../components/SearchCardListComponent.vue'
import BannerAdvert from '../components/BannerAdvertComponent.vue'
import PopularSuburbs from '../components/PopularSuburbsComponent.vue'
import MainFooter from '../components/MainFooterComponent.vue'
export default {
  name: 'SearchCardResult',
  components: {
    StripHeader,
    SearchCardList,
    BannerAdvert,
    PopularSuburbs,
    MainFooter
  }
}
</script>
