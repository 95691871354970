<template>
  <div>
https://www.sketch.com/s/c4bc07c0-9c58-4840-95a5-6aab62307606/a/v81JVkQ
page 26
  </div>
</template>
<script>
export default {
}
</script>
