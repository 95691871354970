<template>
  <div>
    <div class="container mx-auto">
      <p class="text-xl text-black text-2xl font-black text-left pb-10">
        Popular Suburbs
      </p>
      <div class="grid grid-cols-2 gap-x-10 gap-y-5 px-10 md:grid-cols-4 ">
        <div class="flex-1 space-x-0" v-for="item in items" :key="item.title">
          <div class="">
            <img
              class="rounded-xl object-cover w-80 h-80"
              :src="item.image"
              :alt="item.title"
            />
          </div>
          <div class="mt-5">
            <div>
              <div class="text-md pb-2">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopularSuburbsComponent',
  data: () => ({
    items: [
      {
        image: require('@/assets/content/midrand.jpg'),
        title: 'Midrand'
      },
      {
        image: require('@/assets/content/sandton.jpg'),
        title: 'Sandton'
      },
      {
        image: require('@/assets/content/sunninghill.jpg'),
        title: 'Sunninghill'
      },
      {
        image: require('@/assets/content/ferndale.jpg'),
        title: 'Ferndale'
      }
    ]
  })
}
</script>
<style scoped lang="scss">
</style>
