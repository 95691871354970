<template>
  <div class="">
    <div
      class="bg-no-repeat bg-cover bg-center"
      v-bind:style="{
        backgroundImage:
          'linear-gradient(rgba(00, 00, 00, 0.1), rgba(00, 00,00, 0.5)), url(' +
          require('@/assets/content/house1.jpg') +
          ')',
      }"
    >
      <div class="flex flex-col md:flex-row px-20 py-10">
        <img class="md:w-48" src="@/assets/rslogo.png" />
        <div
          class="
            flex-1
            text-white text-lg
            flex flex-row
            justify-around
            md:justify-end
          "
        >
          <p class="pl-10">Sign in</p>
          <p class="pl-10">Sign Up</p>
          <p class="pl-10">Add a property</p>
          <span class="pl-10" @click="$router.push('search')"
            ><SearchIcon class="h-6 w-6"
          /></span>
        </div>
      </div>
      <div class="flex flex-col">
        <p
          class="
            text-white text-center text-4xl
            md:text-5xl
            font-black
            py-10
            md:py-20
            leading-normal
          "
        >
          Find the best property deals and verified <br />
          contractors in your area
        </p>
        <div class="flex flex-col md:flex-row justify-center">
          <div class="flex flex-col px-10 md:pl-0">
            <label for="" class="text-white font-thin">Location</label>
            <input
              type="text"
              class="
                form-input
                px-4
                py-3
                h-20
                text-black
                rounded-2xl
                w-30
                md:w-80
              "
              placeholder="Enter your location"
            />
          </div>
          <div class="flex flex-col px-10 md:pl-0 pt-10 md:pt-0">
            <label for="" class="text-white font-thin">Price range</label>
            <select
              class="
                form-select
                px-4
                py-3
                rounded-2xl
                h-20
                text-black
                w-50
                md:w-80
              "
            >
              <option value="test" class="text-gray">Select a range</option>
              <option value="test" class="text-gray">100k - 200k</option>
            </select>
          </div>
          <div class="flex px-5 w-100 md:w-100">
            <button
              class="
                transition
                ease-in-out
                duration-500
                relative
                top-5
                bg-flipblue
                hover:bg-flipyellow
                text-white
                font-bold
                py-2
                px-4
                h-20
                rounded-full
                w-100
                md:w-40
                flex
                items-center
                justify-center
              "
              @click="$router.push('search-card-result')"
            >
              <SearchIcon class="h-8 w-8" />

            </button>
          </div>
        </div>
        <p
          class="text-center text-white pb-20 pt-10 font-thin underline text-xl"
          @click="$router.push('how-we-estimate-your-potential')"
        >
          How we estimate your profit potential
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeHeaderComponent'
}
</script>
<style scoped lang="scss">
</style>
