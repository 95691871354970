<template>
  <div class="">
    <div class="container mx-auto">
      <p
        class="text-xl text-black text-2xl font-black text-left py-10"
      >
        Featured Properties
      </p>

      <div class="grid md:grid-cols-2 gap-x-16 gap-y-10">
        <div v-for="n in 10" :key="n">
          <div class="">
            <div
              class="h-96 bg-no-repeat bg-cover relative"
              style="background-image: url('https://picsum.photos/1200')"
            >
              <p
                class="
                  absolute
                  text-sm
                  font-medium
                  bottom-5
                  right-10
                  bg-flipyellow
                  px-5
                  py-2
                  rounded-3xl
                "
              >
                Profit Estimate: R120k (20% Return)
              </p>
              <p
                class="
                  absolute
                  top
                  left-0
                  p-3
                  bg-flipblue
                  text-white
                  font-medium
                "
              >
                Added 3 days ago
              </p>
              <p class="absolute top right-0 p-3 text-white"><heart-icon/></p>
            </div>
            <div
              class="flex flex-row justify-between px-8 py-8 bg-white shadow"
            >
              <div class="flex flex-col">
                <p class="text-2xl font-bold font-black">R1900 000</p>
                <p class="md:text-xl font-bold font-medium">
                  3 Bedroom Apartment, Sandton
                </p>
                <p class="font-light">8 Quotes from contractors</p>
              </div>
              <div class="flex flex-col justify-around">
                <div class="flex flex-row justify-between w-32">
                  <div class="text-xl font-bold flex-row flex"><view-grid-icon/> 3</div>
                  <div class="text-xl font-bold flex-row flex"><view-grid-icon/>2</div>
                </div>
                <div class="flex flex-row justify-between w-32">
                  <div class="text-xl font-bold flex-row flex justify-between"><view-grid-icon/> 116m</div>
                  <div class="text-xl font-bold flex-row flex"><view-grid-icon/>2</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturedComponent'
}
</script>
<style scoped lang="scss">
</style>
