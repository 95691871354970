<template>
<div>
  <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap mt-10 p-8 border_std">
    <div class="col-span-12">
      <div>
        <h3 class="text-2xl text-bold mb-3">Recent Sales</h3>
    </div>
    <table class="table-fixed w-100 text-left show_detail_list text-base">
      <thead text-base>
        <tr>
          <th class="w-1/2">Address</th>
          <th class="w-1/4">Last Sold Price</th>
          <th class="w-1/4">Date</th>
        </tr>
      </thead>
  <tbody>
    <tr>
      <td>9 Hildebrandt Road</td>
      <td>R 870 000</td>
      <td>April 2021</td>
    </tr>
    <tr>
      <td>9 Hildebrandt Road</td>
      <td>R 870 000</td>
      <td>April 2021</td>
    </tr>
    <tr>
      <td>9 Hildebrandt Road</td>
      <td>R 870 000</td>
      <td>April 2021</td>
    </tr>
  </tbody>
</table>
<p class="text-xxs text_muted mt-5">
  Disclaimer: <br>
  The information obtained through Property Values is publicly available from the South African Registrar of Deeds. Property Values should not be used as a substitute for independent professional advice.  Fliprop.com does not make any representations about the accuracy of the data displayed and cannot be held liable for any losses suffered as a result of relying on the data. Please refer to our Terms and Conditions.
</p>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'RecentSales'
}
</script>
