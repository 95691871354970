<template>
<div>
  <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap mt-10">
      <div class="col-span-8">
        <div class="details_box">
          <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap content-center">
            <div class="col-span-9">
              <p class="text-base">Property Price:</p>
              <p class="text-4xl mb-5">R 1 200 000</p>
              <p class="text-base mb-5">67 Crescent Road, Midrand, Gauteng <a href="#" class="text_link ml-16 underline">View Map</a></p>
              <p class="text-base">Best profit estimate from contractors:</p>
              <p><span class="yellow_info_box"><p class="text-xs"><CashIcon class="h-6 w-6 icon"/> Profit Estimate: R 120K (20% return)</p></span> <a href="#" class="text_link ml-16 underline">Whats is this?</a></p>
              <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap mt-10">
                <div class="col-span-6">
                  <i class="fas fa-bed mr-4"></i> 3 Bedrooms
                </div>

                <div class="col-span-6">
                  <i class="fas fa-bath mr-4"></i> 3 Bathrooms
                </div>

                <div class="col-span-6">
                  <i class="fas fa-car mr-4"></i> 3 Garages
                </div>

                <div class="col-span-6">
                  <i class="fas fa-border-all mr-4"></i> 3 Floor Size: 116m<sup>2</sup>
                </div>

              </div>
            </div>
            <div class="col-span-3">
              <p class="text-base">State of the property:</p>
              <div class="state_box">
                <p class="text-white text-base">Access Inspection Report</p>
                <i class="fas fa-download icon_white mt-5"></i>
              </div>
              <a href="#" class="text_link underline">See sample report</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-4">
        <div class="agent_block">
          <div class="grid grid-cols-12 grid-flow-row gap-2 content-center flex flex-wrap">
            <div class="col-span-3">
              <img src="@/assets/content/agent.png" alt="" :class="classes" />
            </div>
            <div class="col-span-9">
              <p class="text-base">Jason Morrison</p>
              <a href="#" class="text_link text-xs">Pam Golding Properties</a>
              <div class="star_wrapper">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star neg"></i>
              </div>
              <p class="text_link text-xs">4/5 based on 45 reviews</p><br>
              <a href="#" class="text_link text-xs underline">Show Number</a>
            </div>
            <div class="col-span-12 text-center">
              <a href="#" class="button_blue">SEND EMAIL ENQUIRY</a>

              <img src="@/assets/content/pg_logo.png" alt="" class="mt-5 mx-auto"/>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>

export default {
  name: 'PropertyDetailBlock'
}
</script>
