<template>
  <div class="mt-10">
    <h3 class="text-2xl text-bold mb-3">Renovation Quotes </h3>
    <swiper ref="mySwiper" :options="swiperOptions">
    <swiper-slide class="slide">
      <single-quote />
    </swiper-slide>

    <swiper-slide class="slide">
      <single-quote />
    </swiper-slide>
    <swiper-slide class="slide">
      <single-quote />
    </swiper-slide>

    <swiper-slide class="slide">
      <single-quote />
    </swiper-slide>

    <swiper-slide class="slide">
      <single-quote />
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
  </div>
</template>

<script>
import SingleQuote from '../property-details/SingleQuote.vue'
export default {
  components: {
    SingleQuote
  },
  name: 'carrousel',
  data () {
    return {
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 40,
        pagination: {
          el: '.swiper-pagination'
        }
        // Some Swiper option/callback...
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted () {
    console.log('Current Swiper instance object', this.swiper)
  }
}
</script>
