<template>
  <div class="">
    <div class="container mx-auto">
      <div class="flex flex-1 justify-center">
        <img src="@/assets/content/banner_advert.png" alt="" :class="classes" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerAdvertComponent',
  props: [
    'classes'
  ]
}
</script>
<style scoped lang="scss">
</style>
