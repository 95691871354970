<template>
<div>
  <home-header />
  <div class="container_custom mx-auto pt-4">
    <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap">
      <div class="col-span-7">
        <p class="text-xl text-4xl">
        3 Bedroom Apartment in Midrand
      </p>
      </div>
      <div class="col-span-5">
        <a href="#" class="btn_stroke_std mr-5">EDIT LISTING</a>
        <a href="#" class="text_link mr-5"><ShareIcon class="h-6 w-6 icon"/> Share</a>
        <a href="#" class="text_link"><SaveIcon class="h-6 w-6 icon"/>Save Listing</a>
      </div>
    </div>
    <PropertyImages />
    <PropertyBlock />
    <QuoteBlock />
    <Overview />
    <PropertyDetails />
    <ShowDetails />
    <RecentSales />
    <PropertySlider />

  </div>
  <div class="bg-white-100 pt-20">
      <image-call-to-action class="pb-20" />
      <banner-advert :classes="'h-20 md:h-48'" class="pb-20" />
    </div>
  <div class="bg-black">
      <main-footer />
    </div>
</div>
</template>
<script>
import HomeHeader from '../components/StripHeaderComponent.vue'
import BannerAdvert from '../components/BannerAdvertComponent.vue'
import MainFooter from '../components/MainFooterComponent.vue'
import PropertyImages from '../components/property-details/PropertyImages.vue'
import PropertyBlock from '../components/property-details/PropertyDetailBlock.vue'
import QuoteBlock from '../components/property-details/Quotes.vue'
import Overview from '../components/property-details/Overview.vue'
import PropertyDetails from '../components/property-details/PropertyDetails.vue'
import ShowDetails from '../components/property-details/ShowDetails.vue'
import RecentSales from '../components/property-details/RecentSales.vue'
import PropertySlider from '../components/property-details/PropertySlider.vue'
export default {
  components: {
    HomeHeader,
    BannerAdvert,
    MainFooter,
    PropertyBlock,
    PropertyImages,
    QuoteBlock,
    Overview,
    PropertyDetails,
    ShowDetails,
    RecentSales,
    PropertySlider
  }
}
</script>
