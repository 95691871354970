
<template>
  <div>
    <home-header />
    <how class="pb-20" />
    <div class="bg-gray-100">
      <featured class="pb-20" />
      <banner-advert :classes="'h-full'" class="pb-20" />
      <popular-suburbs class="pb-20" />
    </div>
    <div class="bg-white-100 pt-20">
      <image-call-to-action class="pb-20" />
      <banner-advert :classes="'h-20 md:h-48'" class="pb-20" />
    </div>
    <div class="bg-black">
      <main-footer/>
    </div>
  </div>
</template>

<script>
import HomeHeader from '../components/HomeHeaderComponent.vue'
import How from '../components/HowComponent.vue'
import Featured from '../components/FeaturedComponent.vue'
import BannerAdvert from '../components/BannerAdvertComponent.vue'
import PopularSuburbs from '../components/PopularSuburbsComponent.vue'
import ImageCallToAction from '../components/ImageCallToActionComponent.vue'
import MainFooter from '../components/MainFooterComponent.vue'
export default {
  components: {
    HomeHeader,
    How,
    Featured,
    BannerAdvert,
    PopularSuburbs,
    ImageCallToAction,
    MainFooter
  }
}
</script>
