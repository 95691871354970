
<template>
  <div class="">
    <div class="relative">
      <p class="absolute right-10 top-10" @click="$router.go(-1)">CLOSE</p>
    </div>
    <div class="flex items-center justify-center h-screen">
      <div class="flex flex-col">
        <p
          class="
            text-black text-center text-4xl
            md:text-5xl
            font-black
            py-10
            md:py-20
            leading-normal
          "
        >
          Get access to <br />
          inspection reports
        </p>

        <p class="text-xl pb-10">
          Gain peace of mind by understanding what needs to be fixed and the
          state <br/> of the property. Upgrade your account to access reports on
          Fliprop.
        </p>
        <div class="flex gap-20">
          <div class="border-2 rounded flex-1 justify-center text-center hover:ring-4 hover:ring-flipblue-600" v-for="n in 2" :key="n">
            <p class="font-black text-2xl pt-10 pb-5">Individual Report</p>
            <p class="font-black text-4xl pb-5">R1000 <span class="text-xl">/ month</span></p>
            <p class="text-xl pb-10">Download one report</p>
            <button class="text-white bg-flipblue p-5 rounded-3xl mb-10">Pay Now</button>
          </div>
        </div>
                <a
          href=""
          class="text-center text-black pb-20 pt-10 font-medium underline "
          >See sample report</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  }
}
</script>
