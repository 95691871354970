
<template>
  <div>
https://www.sketch.com/s/c4bc07c0-9c58-4840-95a5-6aab62307606/a/ZOgyGlY
page 24

when there are no listings have this component there
https://www.sketch.com/s/c4bc07c0-9c58-4840-95a5-6aab62307606/a/Om2k9l2
page 38
  </div>
</template>
<script>
export default {
}
</script>
