
<template>
  <div class="">
    <div class="relative">
      <p class="absolute right-10 top-10" @click="$router.go(-1)">CLOSE</p>
    </div>
    <div class="flex items-center justify-center h-screen">
      <div class="flex flex-col">
        <p
          class="
            text-black text-center text-4xl
            md:text-5xl
            font-black
            py-10
            md:py-20
            leading-normal
          "
        >
          How is the Estimated Profit calculated?
        </p>

        <p class="text-center">
          We know how important it is for you to make a profit when flipping a
          property. We have gone the <br />
          extra mile to get estimated cost from service providers on basic
          renovations that would increase the <br />
          value of a purchased property.
        </p>

        <p class="text-center pt-5">
          <span class="font-bold">Renovation Cost</span> is estimated based on
          updates to Kitchen, Bathroom, Tiling, Painting
        </p>

        <p class="text-center">
          <span class="font-bold">Property fixes</span> to existing property
          based on inspection report.
        </p>

        <p class="text-center">
          <span class="font-bold">Market Value</span> Price is the valuation of
          the property on the market
        </p>

        <p class="text-center pt-5">
          <span class="font-bold text-xl"> Estimated Profit Return </span> =
          Market value - Selling Price - Property fixes - Renovation costs
        </p>

        <p class="text-center pt-5">
          Note: What is not factored into this estimate is conveyance fees,
          holding costs, and transfer fees as <br />
          those vary on case by case basis.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  }
}
</script>
