<template>
<div>
  <div class="grid grid-cols-12 grid-flow-row gap-4 content-center flex flex-wrap mt-10 border_std p-8">
    <div class="col-span-12">
      <div class="">
        <h3 class="text-2xl text-bold mb-3">Proper Details</h3>
    </div>
    <div class="col-span-12">
        <table class="table-fixed w-100 text-left prop_detail_list">
  <tbody>
    <tr>
      <td>Listing number</td>
      <td>1092763</td>
    </tr>
    <tr>
      <td>Listing number</td>
      <td>1092763</td>
    </tr>
    <tr>
      <td>Listing number</td>
      <td>1092763</td>
    </tr>
    <tr>
      <td>Listing number</td>
      <td>1092763</td>
    </tr>
    <tr>
      <td>Listing number</td>
      <td>1092763</td>
    </tr>
  </tbody>
</table>
    </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'PropertyDetails'
}
</script>
