import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SignIn from '../views/auth/SignIn.vue'
import SignUpServiceProvider from '../views/auth/SignUpServiceProvider.vue'
import SignUpBroker from '../views/auth/SignUpBroker.vue'
import SignUpInvestor from '../views/auth/SignUpInvestor.vue'
import Search from '../views/Search.vue'
import InspectionPackages from '../views/packages/InspectionPackages.vue'
import SearchCardResult from '../views/SearchCardResult.vue'
import ExplainEstimate from '../views/ExplainEstimate.vue'
import PropertyDetail from '../views/PropertyDetail.vue'

// broker account pages
import BrokerAcountDetails from '../views/accounts/broker/AccountDetails.vue'
import BrokerAddProperty from '../views/accounts/broker/AddProperty.vue'
import BrokerProfile from '../views/accounts/broker/BrokerProfile.vue'
import BrokerChangePassword from '../views/accounts/broker/ChangePassword.vue'
import BrokerDashboard from '../views/accounts/broker/Dashboard.vue'
import BrokerDeleteAccount from '../views/accounts/broker/DeleteAccount.vue'
import BrokerLeads from '../views/accounts/broker/Leads.vue'
import BrokerPropertyListings from '../views/accounts/broker/PropertyListings.vue'

import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/sign-up/broker',
    name: 'SignUpBroker',
    component: SignUpBroker
  },
  {
    path: '/sign-up/investor',
    name: 'SignUpInvestor',
    component: SignUpInvestor
  },
  {
    path: '/sign-up/service-provider',
    name: 'SignUpServiceProvider',
    component: SignUpServiceProvider
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/how-we-estimate-your-potential',
    name: 'ExplainEstimate',
    component: ExplainEstimate
  },
  {
    path: '/search-card-result',
    name: 'SearchCardResult',
    component: SearchCardResult
  },
  {
    path: '/property-detail',
    name: 'PropertyDetail',
    component: PropertyDetail
  },
  {
    path: '/packages/inspection',
    name: 'InspectionPackages',
    component: InspectionPackages
  },

  // broker account routes
  {
    path: '/broker/account-details',
    name: 'BrokerAcountDetails',
    component: BrokerAcountDetails
  },
  {
    path: '/broker/add-property',
    name: 'BrokerAddProperty',
    component: BrokerAddProperty
  },
  {
    path: '/broker/profile',
    name: 'BrokerProfile',
    component: BrokerProfile
  },
  {
    path: '/broker/change-password',
    name: 'BrokerChangePassword',
    component: BrokerChangePassword
  },
  {
    path: '/broker/dashboard',
    name: 'BrokerDashboard',
    component: BrokerDashboard
  },
  {
    path: '/broker/delete-account',
    name: 'BrokerDeleteAccount',
    component: BrokerDeleteAccount
  },
  {
    path: '/broker/leads',
    name: 'BrokerLeads',
    component: BrokerLeads
  },
  {
    path: '/broker/property-listings',
    name: 'BrokerPropertyListings',
    component: BrokerPropertyListings
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
